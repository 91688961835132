import React, { useContext, useEffect } from "react"
import { window } from "browser-monads"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import { navigate, Link } from "gatsby"
import { cartContext } from "../context/cartContext/cartContext"
import Loader from "../components/loader/Loader"
import { userContext } from "../context/userContext/userContext"
import Footer from "../components/footer/footer"
import { base } from "../config/urls"

const BookingDone = (props) => {
  let state = null
  if (typeof window !== "undefined") try {
    state = props.location.state
  } catch (e) {
    console.log(e)
  }
  const userCtx = useContext(userContext)
  const cartCtx = useContext(cartContext)

  useEffect(() => {
    if (!state) {
      navigate(base+"/")
    } else {
      cartCtx.emptyCart()
    }

  }, [])
  // debugger
  return (
    <Layout>
      <SEO title="Booking Confirm"/>
      {!state ?
        <Loader/> :
        <>
          <div className="pv5 ph4-ns ph2 uk-text-center" style={{minHeight: "calc(100vh - 350px)"}}>
            {state.bookingId && <div>
              Your Booking at <span className="color-green">{state["salonName"]}</span> with booking ID <span
              className="color-green">{state["bookingId"]}</span>
            </div>}
            {state.subscriptionId && <div>
              Your Subscription at <span className="color-green">{state["salonName"]}</span> with booking ID <span
              className="color-green">{state["subscriptionId"]}</span>
            </div>}
            {state.paymentId &&
            <div>
              Transaction ID <span className="color-green">{state.paymentId}</span> has been
            </div>}
            <div className="uk-margin">
              <div>
                <svg id="Capa_1" fill="var(--light-blue)" enable-background="new 0 0 515.556 515.556" height="100"
                     viewBox="0 0 515.556 515.556" width="100" xmlns="http://www.w3.org/2000/svg">
                  <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/>
                </svg>
              </div>
              <div className="uk-text-bold" style={{ fontSize: "3rem" }}>
                Confirmed !
              </div>
            </div>
            <div>
              Please share booking ID with salon to start your booking.
            </div>
            <div className="uk-margin">
              <Link className="uk-button uk-button-large uk-button-primary"
                    to={userCtx.user ? "/dashboard" : "/"}
                    replace={true}> {userCtx.user ? "View Booking Details" : "Done"} </Link>

            </div>
          </div>
          <Footer/>
        </>
      }
    </Layout>
  )
}


export default BookingDone
